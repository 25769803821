import TYPE from "./Type";
import axios from "axios";
axios.defaults.withCredentials = true

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const closeAlert = () => dispatch => {
    dispatch({
        type: TYPE.CLOSE_ALERT
    })
}

export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    const body = JSON.stringify({ email, password })
    try {
        const res = await axios.post(`${API_BASE_URL}/dj-rest-auth/login/`, body, config)
        localStorage.setItem("auth_token", res.data.access);
        dispatch({
            type: TYPE.LOGIN_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: TYPE.LOGIN_FAIL
        })
    }
}

export const verify = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        const body = JSON.stringify({ "token": localStorage.getItem('access') });
        try {
            await axios.post(`${API_BASE_URL}/dj-rest-auth/token/verify/`, body, config);
            dispatch({
                type: TYPE.VERIFY_SUCCESS
            });
        } catch (err) {
            dispatch({
                type: TYPE.VERIFY_FAIL
            });
            await dispatch(refresh());
        }
    } else {
        dispatch({
            type: TYPE.GUEST_VIEW
        });
    }
};

export const getUser = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`
            }
        };
        try {
            const res = await axios.get(`${API_BASE_URL}/dj-rest-auth/user/`, config);
            dispatch({
                type: TYPE.GET_USER_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: TYPE.GET_USER_FAIL
            });
        }
    } else {
        dispatch({
            type: TYPE.GUEST_VIEW
        });
    }
}

export const refresh = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        try {
            const res = await axios.post(`${API_BASE_URL}/dj-rest-auth/token/refresh/`, config);
            console.log(res.data);
            localStorage.setItem("auth_token", res.data.access);
            dispatch({
                type: TYPE.REFRESH_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            console.log(err);
            dispatch({
                type: TYPE.REFRESH_FAIL
            })
        }
    } else {
        dispatch({
            type: TYPE.GUEST_VIEW
        })
    }
}


export const logout = () => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    try {
        await axios.post(`${API_BASE_URL}/dj-rest-auth/logout/`, config);
        dispatch({
            type: TYPE.LOGOUT
        });
    } catch (err) {
        dispatch({
            type: TYPE.LOGOUT
        });
    }
}

export const googleLogin = (code) => async dispatch => {
    if (!localStorage.getItem('access')) {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        const body = JSON.stringify({ code })

        try {
            const res = await axios.post(`${API_BASE_URL}/dj-rest-auth/google/`, body, config)
            localStorage.setItem("auth_token", res.data.access);

            dispatch({
                type: TYPE.LOGIN_SUCCESS,
                payload: res.data
            })
            
        } catch (err) {
            console.log("Error Response:", err.response ? err.response.data : err.message);

            dispatch({
                type: TYPE.LOGIN_FAIL
            })
        }
    } else {
        dispatch(verify());
        dispatch(getUser());
    }
}

export const fetchUserRole = async (token) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/check_user_role/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.role; 
    } catch (error) {
        console.error("Error fetching user role:", error);
        throw error; 
    }
};

export const contactFormSubmit = async (contactFormData) => {

    const token = localStorage.getItem("auth_token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Token ${token}`,
      },
    };
  
    try {
      const response = await axios.post(`${API_BASE_URL}/save_contact_us_data/`, JSON.stringify(contactFormData), config);
      return response.data; 
    } catch (err) {
      console.error('Error adding goal:', err); 
      throw err; 
    }
  };

  export const fetchNurses = async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_nurses/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching nurses:", error);
      throw error;
    }
  };

  export const sendNurseRequest = async (requestData, token) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/send_request_to_nurse/`, requestData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error sending nurse request:", error);
      throw error;
    }
  };

  export const fetchPatients = async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_patients/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching patients:", error);
      throw error;
    }
  };

  export const addPrescription = async (prescriptionData, token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/prescriptions/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(prescriptionData),
    });

    if (!response.ok) {
        throw new Error('Failed to add prescription');
    }

    return response.json();
};