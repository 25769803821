import React from 'react';

const Footer = () => {

    return (
        <div className="footer">
            <div className="sb__footer section__padding">
                <hr></hr>
                <div className="sb__footer-links">
                    <div className="sb__footer-links-div">
                        <h4>QUICK LINKS</h4>
                        <a href="/">
                            <p>Home</p>
                        </a>
                        <a href="/#about-us">
                            <p>About Us</p>
                        </a>
                        <a href="/#care-services">
                            <p>Nursing Care</p>
                        </a>
                        <a href="/#care-services">
                            <p>Physiotherapy</p>
                        </a>
                        <a href="/#care-services">
                            <p>Pharmacy</p>
                        </a>
                    </div>
                    <div className="sb__footer-links-div">
                        <h4>SUPPORT</h4>
                        <a href="/#contact-us">
                            <p>Transition Care</p>
                        </a>
                        <a href="/#contact-us">
                            <p>Physio&Rehab</p>
                        </a>
                        <a href="/#contact-us">
                            <p>App Features</p>
                        </a>
                        <a href="/#contact-us">
                            <p>News&Media</p>
                        </a>
                        <a href="/#contact-us">
                            <p>Careers</p>
                        </a>
                    </div>
                    <div className="sb__footer-links-div">
                        <h4>For Parents</h4>
                        <a href="/#contact-us">
                            <p>Contact Us</p>
                        </a>
                        <a href="/#contact-us">
                            <p>Sitemap</p>
                        </a>
                    </div>
                    <div className="sb__footer-links-div">
                        <h4>Coming soon on</h4>
                        <div className='socialmedia'>
                            <p><i aria-hidden="true" className="fab fa-facebook-f" /></p>
                            <p><i aria-hidden="true" className="fab fa-twitter" /></p>
                            <p><i aria-hidden="true" className="fab fa-linkedin-in" /></p>
                            <p><i aria-hidden="true" className="fab fa-instagram" /></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;