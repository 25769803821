import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { refresh } from "../reducer/Actions"; 
import Home from "../screens/home/Home";
import Layout from "../layout/Layout";
import CareServices from "../CareServices";
import Doctors from "../Doctors";
import TakeCarers from "../TakeCarers";
import Appointments from "../Appointments";
import Patients from "../Patients";
import EditPatient from "../EditPatient";
import EditDoctor from "../EditDoctor";
import Nurses from "../Nurses";
import Store from "../Store";

const AppInitializer = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(refresh());
    }, [dispatch]);

    return null; 
};

const Main = () => {
    return (
        <Provider store={Store}>
            <Router>
                <div className="page-box">
                    <Layout />
                    <div className="app-container" style={{ position: "fixed", left: "0", right: "0" }}>
                        <AppInitializer />
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/services" component={CareServices} />
                            <Route exact path="/doctors" component={Doctors} />
                            <Route exact path="/nurses" component={Nurses} />
                            <Route exact path="/take-carers" component={TakeCarers} />
                            <Route exact path="/patients" component={Patients} />
                            <Route exact path="/edit-patient" component={EditPatient} />
                            <Route exact path="/edit-doctor" component={EditDoctor} />
                            <Route exact path="/appointments" component={Appointments} />
                        </Switch>
                    </div>
                </div>
            </Router>
        </Provider>
    );
};

export default Main;
