import React, { useState, useEffect } from "react";
import { NURSE_IMG1 } from "./assets/images/imagespath/imagepath";
import Footer from './components/Footer';
import { fetchNurses, sendNurseRequest } from "./reducer/Actions";

const Nurses = () => {
    const token = localStorage.getItem("auth_token");
    const [data, setData] = useState([]);
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userMobile, setUserMobile] = useState("");
    const [nurseId, setNurseId] = useState(null);
    const [time, setTime] = useState("");
    const [loading, setLoading] = useState(false);
    const [ModalVisible, setModalVisible] = useState(false);


    useEffect(() => {
        const loadNurses = async () => {
            try {
                const nurses = await fetchNurses(token); 
                setData(nurses);
            } catch (err) {
                console.log(err.message);
            }
        };

        loadNurses();
    }, [token]);
    

    const handleSendRequest = async () => {
        setLoading(true);
    
        const requestData = {
          user_name: userName,
          user_email: userEmail,
          user_mobile: userMobile,
          nurse_id: nurseId,
          time: new Date(time).toISOString()
        };
    
        try {
          await sendNurseRequest(requestData, token); 
          setUserName("");
          setUserEmail("");
          setUserMobile("");
          setTime("");
          setNurseId(null);
          setModalVisible(false);
        } catch (err) {
          console.log(err.message);
        } finally {
          setLoading(false);
        }
      };

    const openModal = (nurseId) => {
        setNurseId(nurseId);
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleUserNameChange = (e) => setUserName(e.target.value);
    const handleUserEmailChange = (e) => setUserEmail(e.target.value);
    const handleUserMobileChange = (e) => setUserMobile(e.target.value);
    const handleTimeChange = (e) => setTime(e.target.value);

    return (
        <div className='main-content' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)' }}>
            <div className='main-content-wrap'>
                <div className='page-content'>
                    {data.length > 0 ? (
                        <div class="row">
                            {data.map((nurse, index) => (
                                <div className="col-12 col-md-9" key={index}>
                                    <div className="nurse-card">
                                        <div className="nurse-content">
                                            <div className="nurse-img-box">
                                                <img src={nurse.image || NURSE_IMG1} alt={nurse.first_name} />
                                            </div>
                                            <div>
                                                <h4 className="nurse-name">{nurse.first_name} {nurse.last_name}</h4>
                                                <p className="nurse-specialty">{nurse.qualifications || 'Orthopedic'}</p>
                                                <div className="nurse-info-box">
                                                    <p className="nurse-description">
                                                        <i className="fas fa-info-circle"></i> {nurse.shift_hours || '9 AM to 5 PM'}
                                                    </p>
                                                    <p className="nurse-experience">
                                                        <i className="fas fa-briefcase"></i> {nurse.experience} years of experience
                                                    </p>
                                                    <p className="nurse-address">
                                                        <i className="fas fa-map-marker-alt"></i> {nurse.address || 'Visakhapatnam'}
                                                    </p>
                                                    <p className="nurse-contact">
                                                        <i className="fas fa-phone"></i> Contact: {nurse.contact || 'N/A'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="nurse-card-bottom">
                                            <button className="nurse-btn btn-primary" onClick={() => openModal(nurse.id)}>Request Nurse</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div
                            className="ultra-paragraph text-center"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ type: "spring", damping: 15, stiffness: 100 }}
                        >
                            <i className="fas fa-exclamation-triangle icon-large"></i>
                            No nurses found at the moment. Please check back later, <br />
                            or try searching with different criteria.
                            <br /> <br />
                            We are continually updating our database to ensure you find the best care providers.
                            <br />
                            Thank you for your patience and understanding!
                        </div>

                    )}


                    {/* Add Nurse Request Modal */}
                    <div className={`custom-modal ${ModalVisible ? 'show' : ''}`} id="contact-modal" tabIndex="-1" aria-hidden="true">
                        <div className="custom-modal-dialog">
                            <div className="custom-modal-content">
                                <div className="custom-modal-header">
                                    <h5 className="custom-modal-title">Send Nurse Request</h5>
                                </div>
                                <div className="custom-modal-body">
                                    <div className="mb-3">
                                        <label htmlFor="userName" className="form-label">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="userName"
                                            value={userName}
                                            onChange={handleUserNameChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="userEmail" className="form-label">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="userEmail"
                                            value={userEmail}
                                            onChange={handleUserEmailChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="userMobile" className="form-label">Mobile</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="userMobile"
                                            value={userMobile}
                                            onChange={handleUserMobileChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="time" className="form-label">Time</label>
                                        <input
                                            type="datetime-local"
                                            className="form-control"
                                            id="time"
                                            value={time}
                                            onChange={handleTimeChange}
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="actions">
                                        <button type="button" className="btn btn-secondary mr-2" onClick={closeModal}>Close</button>
                                        <button type="button" className="btn btn-primary ml-2" onClick={handleSendRequest} disabled={loading}>
                                            {loading ? "Sending..." : "Send Request"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Nurses;
