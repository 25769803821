import React, { useState } from 'react';
import { DEPARTMENT_IMG1, DEPARTMENT_IMG2, DEPARTMENT_IMG3 } from "./assets/images/imagespath/imagepath"
import Footer from './components/Footer';
import { contactFormSubmit } from '../src/reducer/Actions';


const CareServices = () => {
    const [contactFormData, setContactFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        service: '',
        description: ''
    });
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);


    const handleContactChange = (e) => {
        const { name, value } = e.target;
        setContactFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleContactSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formDataToSend = { ...contactFormData };

            if (contactFormData.service === "Others") {
                formDataToSend.service = contactFormData.description;
            }

            await contactFormSubmit(formDataToSend);

            setContactFormData({
                name: '',
                email: '',
                mobile: '',
                service: '',
                description: ''
            });
            setShowModal(false);
            setConfirmationModalVisible(true);
        } catch (error) {
            console.error("Contact form submission failed:", error);
        } finally {
            setLoading(false);
        }
    };

    const openContactModal = () => {
        setShowModal(true);
    };

    const closecontactModal = () => {
        setShowModal(false);
        setConfirmationModalVisible(false);
    };

    return (
        <div className='main-content' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)' }}>
            <div className="main-content-wrap">
                <div className="page-content">
                    <div className='mt-5'>
                        <div className="text-center">
                            <h4 className="mt-0 default_color">OUR SERVICES</h4>
                            <p className="mx-auto mb-5 responsive-paragraph">
                                Our service portfolio is curated to meet all your expectations of home health care,
                                run by the best health care professionals and highly trained staff.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="card department bg-light bg-gradient mb-md-0">
                                    <img src={DEPARTMENT_IMG2} className="card-img-top" width="400" height="250" alt="" />
                                    <div className="card-body">
                                        <h3 className="h4 mt-0 Title">NURSING</h3>
                                        <p>
                                            Medical nurses specialised in critical care , attendants for short term nursing procedures
                                        </p>
                                        <div className="button-box pb-2">
                                            <button type="button" className="btn btn-outline-primary">
                                                See More<span className="btn-icon fas fa-ellipsis-vertical ms-2"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="card department bg-light bg-gradient">
                                    <img src={DEPARTMENT_IMG1} className="card-img-top" width="400" height="250" alt="" />
                                    <div className="card-body">
                                        <h3 className="h4 mt-0 Title">ELDER CARE</h3>
                                        <p>
                                            Critical care to long term rehabiliation , we offer multi disciplinary service with a focus on geriatrics
                                        </p>
                                        <div className="button-box pb-2">
                                            <button type="button" className="btn btn-outline-primary">
                                                See More<span className="btn-icon fas fa-ellipsis-vertical ms-2"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="card department bg-light bg-gradient mb-md-0">
                                    <img src={DEPARTMENT_IMG3} className="card-img-top" width="400" height="250" alt="" />
                                    <div className="card-body">
                                        <h3 className="h4 mt-0 Title">MEDICAL EQUIPMENT</h3>
                                        <p>
                                            Buy or rent equipment for respiratory , cardiac, orthopaedic cases or mobility aids
                                        </p>
                                        <div className="button-box pb-2">
                                            <button type="button" className="btn btn-outline-primary">
                                                See More<span className="btn-icon fas fa-ellipsis-vertical ms-2"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!showModal && (
                        <div class="add-action-box">
                            <button class="btn btn-dark btn-lg btn-square rounded-pill" onClick={() => openContactModal()}>
                                <span><i class="fas fa-phone"></i></span>
                            </button>
                        </div>
                    )}
                    <Footer />
                </div>
            </div>
            {/* Contact Us Modal */}
            < div className={`custom-modal ${showModal ? 'show' : ''}`} id="contact-modal" tabIndex="-1" >
                <div className="custom-modal-dialog">
                    <div className="custom-modal-content">
                        <div className="custom-modal-header">
                            <h5 className="custom-modal-title">Contact Us</h5>
                        </div>
                        <div className="custom--modal-body">
                            <form onSubmit={handleContactSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="contact-name" className="form-label">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="contact-name"
                                        name="name"
                                        value={contactFormData.name}
                                        onChange={handleContactChange}
                                        placeholder="Your Name"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="contact-email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="contact-email"
                                        name="email"
                                        value={contactFormData.email}
                                        onChange={handleContactChange}
                                        placeholder="Your Email"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="contact-phone" className="form-label">Phone Number</label>
                                    <input
                                        type="tel"
                                        className="form-control"
                                        id="contact-phone"
                                        name="mobile"
                                        value={contactFormData.mobile}
                                        onChange={handleContactChange}
                                        placeholder="Your mobile Number"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="contact-service" className="form-label">Service</label>
                                    <select
                                        className="form-control"
                                        id="contact-service"
                                        name="service"
                                        value={contactFormData.service}
                                        onChange={handleContactChange}
                                    >
                                        <option value="">Select a Service</option>
                                        <option value="Post Operative Care">Post Operative Care</option>
                                        <option value="Physiotherapy">Physiotherapy</option>
                                        <option value="Paralysis Care">Paralysis Care</option>
                                        <option value="Pulmanory & Tracheostemy  Care">Pulmanory & Tracheostemy  Care</option>
                                        <option value="Delivery Of Medicines">Delivery Of Medicines</option>
                                        <option value="24 Hours Bedside Care">24 Hours Bedside Care</option>
                                        <option value="Arranging Home Visits">Arranging Home Visits</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                                {contactFormData.service === "Others" && (
                                    <div className="mb-3">
                                        <label htmlFor="contact-phone" className="form-label">Description</label>
                                        <div className="col-sm-12">
                                            <input
                                                type="text"
                                                name="description"
                                                value={contactFormData.description}
                                                onChange={handleContactChange}
                                                className="form-control"
                                                placeholder="Please describe your service"
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={closecontactModal}>Close</button>

                                    {loading ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    ) : (
                                        <>
                                            <button type="submit" className="btn btn-warning">Send Message</button>                    </>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >

            {/* Confirmation Modal */}
            < div class="modal fade show" id="modal-11" tabindex="-1" role="dialog" style={{ display: confirmationModalVisible ? "block" : "none" }}>
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <i class="icofont-close-line"></i>
                        </button>

                        <div class="modal-header">
                            <h5 class="modal-title">Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            Thank you for your interest.
                            We will contact you soon.
                        </div>
                        <div class="modal-footer">
                            <div class="actions">
                                <button type="button" class="btn btn-secondary" onClick={closecontactModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );
};

export default CareServices;

