import React, { useState, useEffect } from 'react';
import Footer from './components/Footer';
import { fetchPatients, addPrescription, fetchUserRole } from './reducer/Actions';

const Patients = () => {
    const token = localStorage.getItem("auth_token");
    const [data, setData] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [medication, setMedication] = useState("");
    const [instructions, setInstructions] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [ModalVisible, setModalVisible] = useState(false);
    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
    const [role, setRole] = useState("");


    useEffect(() => {
        const getUserRole = async () => {
            const token = localStorage.getItem("auth_token");

            if (token) {
                try {
                    const userRole = await fetchUserRole(token);
                    setRole(userRole);
                    console.log("role", userRole)
                } catch (error) {
                    console.error('Failed to check user role');
                }
            }
        };
        getUserRole();
    }, []);

    useEffect(() => {
        const loadPatients = async () => {
            try {
                const patients = await fetchPatients(token);
                setData(patients);
            } catch (err) {
                console.log(err.message);
            }
        };

        loadPatients();
    }, [token]);


    const handleAddPrescription = async (patientId) => {
        setIsLoading(true);

        const prescriptionData = {
            patient: patientId,
            medication: medication,
            instructions: instructions,
        };

        try {
            await addPrescription(prescriptionData, token);
            setMedication("");
            setInstructions("");
            setSelectedPatient(null);
            setModalVisible(false);
        } catch (err) {
            setIsErrorModalVisible(true);
            console.error(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const openModal = (patientId) => {
        if (role === "Doctor") {
            setSelectedPatient(patientId);
            setModalVisible(true);
        } else {
            setIsErrorModalVisible(true);
        }
    };


    const closeModal = () => {
        setModalVisible(false);
        setIsErrorModalVisible(false);
    };


    const handleMedicationChange = (e) => setMedication(e.target.value);
    const handleInstructionsChange = (e) => setInstructions(e.target.value);

    return (
        <div className='main-content' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)' }}>
            <div className='main-content-wrap'>
                <div className='page-content'>
                    <div className="card mb-0">
                        <div className="card-body">
                            {data.length > 0 ? (
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr className="bg-primary text-white">
                                                <th scope="col">Name</th>
                                                <th scope="col">ID</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Last Login</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((patient) => (
                                                <tr key={patient.id}>
                                                    <td>
                                                        <strong>{patient.first_name} {patient.last_name}</strong>
                                                    </td>
                                                    <td>
                                                        <span className="text-muted">{patient.id}</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-muted">{patient.email}</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-muted text-nowrap">
                                                            {new Date(patient.last_login).toLocaleDateString()}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="actions">
                                                            {/* <a href="edit-patient" className="btn btn-dark btn-sm btn-square rounded-pill">
                                                                <span className="btn-icon"><i className="fas fa-external-link-alt"></i></span>
                                                            </a> */}
                                                            <button
                                                                className="btn btn-primary btn-sm btn-square rounded-pill"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#prescription-modal"
                                                                onClick={() => openModal(patient.id)}
                                                            >
                                                                <span className="btn-icon"><i className="fas fa-edit"></i></span>
                                                            </button>
                                                            {/* <button className="btn btn-error btn-sm btn-square rounded-pill">
                                                                <span className="btn-icon"><i className="fas fa-trash"></i></span>
                                                            </button> */}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div
                                    className="ultra-paragraph text-center"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ type: "spring", damping: 15, stiffness: 100 }}
                                >
                                    <i className="fas fa-exclamation-triangle icon-large"></i>
                                    No patients found at the moment. Please check back later, <br />
                                    or try searching with different criteria.
                                    <br /> <br />
                                    We are continually updating our records to provide you with the most accurate information.
                                    <br />
                                    Thank you for your patience and understanding!
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Add Prescription Modal */}
                    <div className={`custom-modal ${ModalVisible ? 'show' : ''}`} id="contact-modal" tabIndex="-1" aria-hidden="true">
                        <div className="custom-modal-dialog">
                            <div className="custom-modal-content">
                                <div className="custom-modal-header">
                                    <h5 className="custom-modal-title">Prescription Form</h5>
                                </div>
                                <div className="custom--modal-body">
                                    <div className="mb-3">
                                        <label htmlFor="medication" className="form-label">Medication</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="medication"
                                            value={medication}
                                            onChange={handleMedicationChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="instructions" className="form-label">Instructions</label>
                                        <textarea
                                            className="form-control"
                                            id="instructions"
                                            rows="3"
                                            value={instructions}
                                            onChange={handleInstructionsChange}
                                        ></textarea>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => handleAddPrescription(selectedPatient)}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Adding...' : 'Add Prescription'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     {/* Error Modal */}
                    < div class="modal fade show" id="modal-11" tabindex="-1" role="dialog" style={{ display: isErrorModalVisible ? "block" : "none" }}>
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                    <i class="icofont-close-line"></i>
                                </button>

                                <div class="modal-header">
                                    <h5 class="modal-title" style={{color : "red" , textAlign : "center", width : "100%"}}>Error</h5>
                                </div>
                                <hr style={{ borderTop: "2px solid black", width: "100%", margin: "0px" }} />
                                <div class="modal-body" style={{fontSize : "x-large"}}>
                                Only Doctors can add prescriptions.
                                </div>
                                <div class="modal-footer">
                                    <div class="actions">
                                        <button type="button" class="btn btn-secondary" onClick={closeModal}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Patients;
