import React from "react";
import Navbar from "../components/Navbar";
import { connect } from "react-redux";
import { useEffect } from "react";
import {
  verify,
  getUser,
  googleLogin,
} from "../reducer/Actions";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const Layout = (props) => {
  let location = useLocation();

  useEffect(() => {
    const values = queryString.parse(location.search);
    const code = values.code;
    const handleGoogleLogin = async (code) => {
      try {
        const status = await props.googleLogin(code);
        if (status === 200) {
          console.log("Google login successful, fetching user role");
          const roleResponse = await props.checkUserRole();
          console.log("fetching user role successful", roleResponse);
        }
      } catch (err) {
        console.error("Google login failed", err);
      }
    };

    if (code) {
      handleGoogleLogin(code);
    } else {
      props.verify();
      props.getUser();
    }
  }, [location]);

  return (
    <div>
      <Navbar />
      {props.children}
    </div>  
  );
};

const mapStateToProps = (state) => {
  return {
    message: state.AuthReducer.message,
    access: state.AuthReducer.access,
    refresh: state.AuthReducer.refresh,
    isAuthenticated: state.AuthReducer.isAuthenticated,
    user: state.AuthReducer.user,
  };
};

export default connect(mapStateToProps, {
  verify,
  getUser,
  googleLogin,
})(Layout);
